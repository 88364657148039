* {
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

html {
  overflow-x: hidden;
  font-size: 1vw;
  font-family: "Stolzl";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100vh; }

body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #fdfffc;
  /* before asia/italy chosen */ }

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: black; }
  .preloader__video {
    min-width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .preloader video {
    width: 100%;
    height: auto; }

@font-face {
  font-family: "Fontin Sans CR";
  src: local("Fontin Sans CR Regular"), local("FontinSansCR-Regular"), url("../fonts/fontinsanscrregular.woff2") format("woff2"), url("../fonts/fontinsanscrregular.woff") format("woff"), url("../fonts/fontinsanscrregular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Fontin Sans CR";
  src: local("Fontin Sans CR Italic"), local("FontinSansCR-Italic"), url("../fonts/fontinsanscritalic.woff2") format("woff2"), url("../fonts/fontinsanscritalic.woff") format("woff"), url("../fonts/fontinsanscritalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "Fontin Sans CR";
  src: local("Fontin Sans CR Bold"), local("FontinSansCR-Bold"), url("../fonts/fontinsanscrbold.woff2") format("woff2"), url("../fonts/fontinsanscrbold.woff") format("woff"), url("../fonts/fontinsanscrbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Fontin Sans CR";
  src: local("Fontin Sans CR Bold Italic"), local("FontinSansCR-BoldItalic"), url("../fonts/fontinsanscrbolditalic.woff2") format("woff2"), url("../fonts/fontinsanscrbolditalic.woff") format("woff"), url("../fonts/fontinsanscrbolditalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: "Synerga Pro";
  src: local("Synerga Pro Regular"), local("SynergaPro-Regular"), url("../fonts/Synergaproregular.woff2") format("woff2"), url("../fonts/Synergaproregular.woff") format("woff"), url("../fonts/Synergaproregular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

.mobile {
  display: none; }

.desktop {
  display: initial; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Fontin Sans CR", sans-serif;
  font-weight: 700;
  letter-spacing: 0.05em;
  font-variant: small-caps; }

.italy h1,
.italy h2,
.italy h3,
.italy h4,
.italy h5,
.italy h6,
.half.second h1,
.half.second h2,
.culture.italy h4 {
  font-family: "Synerga Pro", serif;
  font-weight: 400;
  letter-spacing: 0.05em;
  font-variant: unset; }

p {
  font-family: "Fontin Sans CR";
  letter-spacing: 0.04em;
  font-weight: 400;
  line-height: 135%; }

.poppins {
  font-family: "Poppins", sans-serif;
  font-variant: normal; }

/* .encodesans{
  font-family: 'Encode Sans', sans-serif;
} */
/* .opensans{
  font-family: 'Open Sans', sans-serif;
} */
.libre {
  font-family: "Libre Baskerville", serif; }

/* DOORS & SLIDER*/
#doors {
  height: 100vh;
  width: 100vw;
  display: flex;
  position: relative;
  overflow: hidden; }

#doors .half {
  width: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1.3s ease-in-out; }

.half__birdBg,
.half__title {
  display: none; }

#doors .half.opened {
  position: relative;
  width: 85%; }
  #doors .half.opened .half__birdBg {
    position: absolute;
    display: block;
    top: -35px;
    z-index: 1; }
    #doors .half.opened .half__birdBg-asia {
      color: #f3ebe0;
      mix-blend-mode: overlay; }
    #doors .half.opened .half__birdBg-italy {
      color: #eca86a;
      opacity: 0.45; }
  #doors .half.opened .half__title {
    position: absolute;
    top: 83px;
    display: block;
    text-align: center;
    font-size: 24px;
    line-height: 1.35; }
    #doors .half.opened .half__title span {
      display: block; }
    #doors .half.opened .half__title-asia {
      width: 250px;
      color: #f9f6f2;
      mix-blend-mode: soft-light; }
    #doors .half.opened .half__title-italy {
      width: 250px;
      color: #141514;
      mix-blend-mode: color-burn; }
  #doors .half.opened.second {
    background-position: center top;
    background-size: cover; }

@media screen and (max-width: 768px) {
  #doors .half.opened .half__birdBg {
    left: 82%;
    top: -18px;
    z-index: 0;
    transform-origin: center;
    transform: rotate(2deg) translateX(-50%); } }

#doors .half.shrinked {
  width: 20%;
  cursor: pointer;
  z-index: 4;
  background-size: cover; }
  #doors .half.shrinked-italy {
    background-position: left -100px top;
    filter: sepia(0.35); }
  #doors .half.shrinked-asia {
    background-position: center bottom;
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: multiply; }
    #doors .half.shrinked-asia .half__birdBg,
    #doors .half.shrinked-asia .half__title,
    #doors .half.shrinked-asia .header__btn,
    #doors .half.shrinked-asia .gotoaction,
    #doors .half.shrinked-asia .darker,
    #doors .half.shrinked-asia .horizont,
    #doors .half.shrinked-asia .content,
    #doors .half.shrinked-asia .vertical {
      display: none; }

.frame {
  position: absolute;
  height: 40%;
  width: 40%;
  z-index: 1; }

.half.shrinked .frame {
  width: 15%; }

.darker {
  position: absolute;
  width: 100%;
  height: 30%;
  background: #141514;
  opacity: 0.55;
  top: 25%; }

.half.second .frame {
  border-color: #141514 !important; }

.half.second.shrinked .frame {
  border-color: white !important; }

.frame:nth-of-type(1) {
  top: 2vw;
  left: 2vw;
  border-left: 1px solid #dab077;
  border-top: 1px solid #dab077; }

.frame:nth-of-type(2) {
  top: 2vw;
  right: 2vw;
  border-top: 1px solid #dab077;
  border-right: 1px solid #dab077; }

.frame:nth-of-type(3) {
  left: 2vw;
  bottom: 2vw;
  border-left: 1px solid #dab077;
  border-bottom: 1px solid #dab077; }

.frame:nth-of-type(4) {
  bottom: 2vw;
  right: 2vw;
  border-right: 1px solid #dab077;
  border-bottom: 1px solid #dab077; }

.simetric {
  position: absolute;
  bottom: 2%;
  right: 50%;
  transform: translate(50%, 0);
  width: 5vw; }

.gotoaction {
  width: 30vw;
  margin-bottom: 20%;
  margin-right: 15%;
  position: relative;
  z-index: 2; }

.gotoaction p {
  font-size: 0.8rem;
  margin-right: 15%;
  line-height: 140%; }

.gotoaction p.orange {
  color: #dab077;
  margin-top: 1vw; }

.gotoaction h1 {
  color: #dab077;
  font-size: 2.8rem; }

.second .gotoaction h1 {
  color: #dab077; }

.horizont {
  position: absolute;
  top: 50%;
  left: 0;
  width: 50vw;
  object-fit: contain; }

.flower {
  position: absolute;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s ease-in-out; }

.flower.visible {
  position: absolute;
  opacity: 1;
  z-index: 20; }

.flower.first {
  position: absolute;
  left: 4%;
  width: 2vw;
  top: 50%;
  transform: translate(0, -50%); }

.flower.second {
  position: absolute;
  left: unset;
  right: 4%;
  width: 2vw;
  top: 50%;
  transform: translate(0, -50%); }

.half.second .flower.first {
  transform: translate(0, -50%) rotate(90deg);
  width: 4vw;
  left: 3%; }

.half.second .flower.second {
  transform: translate(0, -50%) rotate(-90deg);
  width: 4vw;
  right: 3%; }

.dish-bg {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  z-index: -20; }

.dish-bg.visible {
  opacity: 1;
  z-index: 0;
  width: 100%;
  object-fit: cover;
  top: 25%;
  height: 80vh; }

.switch-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 1;
  text-transform: uppercase;
  border-bottom: 2px solid;
  opacity: 0;
  transition: opacity 0.3s ease-in-out; }
  .switch-logo_italy {
    font-family: "Synerga Pro", serif;
    color: #fafeff;
    border-color: #fafeff; }
  .switch-logo_asia {
    font-family: "Fontin Sans CR", sans-serif;
    color: #dab077;
    border-color: #dab077; }
  .switch-logo_sm {
    padding-bottom: 6px;
    border-bottom: 1px solid; }

.switch-logo.visible {
  opacity: 1; }

.vertical {
  position: absolute;
  height: 100%;
  bottom: -2vw;
  right: 6vw;
  opacity: 0;
  transition: opacity 0.3s ease-in-out; }

.visible {
  opacity: 1 !important; }

.burger,
.half .pin {
  position: absolute;
  width: 2.5vw;
  top: 8%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  z-index: 2; }

.doorlogo {
  position: absolute;
  width: 6vw;
  top: 1vw;
  opacity: 0; }
  .doorlogo-asia {
    color: #b59586; }
  .doorlogo-italy {
    color: #141514; }

.half.second .doorlogo {
  width: 15vw; }

.burger {
  left: 7%;
  z-index: 99999999999; }

.burger:active,
.discover:active {
  transform: translate(0, 2px); }

.half .pin {
  right: 7%; }

.address {
  position: absolute;
  top: 18%;
  right: 7%;
  text-align: right;
  font-size: 0.8rem;
  color: #f9f6f2;
  opacity: 0;
  transition: opacity 0.3s ease-in-out; }

.half.second .address {
  color: #000000; }

.half .socials {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: 28%;
  position: absolute;
  z-index: 2;
  cursor: pointer; }

.half .socials i {
  margin: 0 0.5vw;
  font-size: 1.1rem;
  color: #eca86a;
  cursor: pointer; }

.half.second .socials i {
  color: #f0f1f3; }

.discover {
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 20;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer; }

.discover p {
  color: rgba(236, 168, 106, 0.65);
  font-size: 0.8rem;
  font-weight: 500; }

.half.second .discover p {
  color: rgba(20, 21, 20, 0.65); }

.discover img {
  height: 5vw; }

/* POPUP */
#popup.visible {
  opacity: 1;
  z-index: 5; }

.popup {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(17, 0, 5, 0.85);
  opacity: 0;
  z-index: -1;
  transition: all 0.6s ease-in-out; }
  .popup::after, .popup::before {
    content: "";
    position: absolute;
    left: 50%;
    display: block;
    width: 1px;
    height: 330px;
    background-color: #fff; }
  .popup__icon {
    position: absolute;
    top: 0;
    display: none;
    width: 45px;
    height: auto;
    margin-left: 50px;
    color: #c4a394;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: top 0.3s ease; }
    .popup__icon:hover, .popup__icon:focus {
      opacity: 0.9; }
    .popup__icon:active {
      top: 1px; }
  .popup::after {
    top: 0;
    margin-top: 30px; }
  .popup::before {
    bottom: 0;
    margin-bottom: 30px; }
  .popup__half {
    display: flex;
    width: 50%; }
  .popup__pdfs {
    margin-top: 275px; }
  .popup__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 50%; }
  .popup__copyright {
    margin-top: 27px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 12px;
    color: #b4b3a5;
    font-family: sans-serif; }

.pdfs {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column; }
  .pdfs__container {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 62%;
    margin: 55px 0 54px; }
  .pdfs__link {
 text-align: center; 
    text-decoration: none;
    color: #c4a394;
    font-family: "Synerga Pro";
    font-size: 1.4rem;
    border: none;
    background-color: transparent;
    outline: none; }
 .pdfs__link-right{
 transform: translate(27px, 0); }
 .pdfs__icon {
    position: absolute;
    width: 32px;
    height: 41px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.mapper {
  padding-top: 65px;
  padding-bottom: 81px; }
  .mapper__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 420px;
    margin: 0 auto; }
  .mapper__addr {
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.35;
    color: #b4b3a5; }
  .mapper__map {
    height: 262px;
    width: 100%;
    margin: 39px 0 74px; }
  .mapper__container {
    margin-left: 9px; }
  .mapper__workTime {
    color: #dab077;
    font-size: 14px;
    line-height: 1.35;
    margin-bottom: 14px; }
  .mapper__time {
    font-size: 16px;
    color: #f9f6f2; }
  .mapper__time:nth-last-child(n + 2) {
    margin-bottom: 33px; }
  .mapper__bot {
    position: relative;
    display: block;
    margin-top: auto;
    text-align: center;
    text-decoration: underline;
    font-size: 16px;
    color: #c4a394;
    line-height: 1.35; }
    .mapper__bot::after {
      content: "";
      position: absolute;
      bottom: -69px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 85px;
      height: 38px;
      background-image: url(../img/emblem.svg);
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.1; }

.socials {
  display: flex;
  width: 350px;
  margin: 0 auto;
  text-align: center; }
  .socials__asia, .socials__italy {
    display: flex;
    flex-direction: column; }
  .socials__link {
    display: inline-block;
    width: 55px;
    padding: 31px 0 10px;
    text-decoration: none;
    color: #c4a394;
    font-size: 10px;
    background-repeat: no-repeat;
 background-position: center;}
    .socials__link-fb {
      background-image: url("../img/soc-facebook.svg");
      margin-bottom: 23px; }
    .socials__link-insta {
      background-image: url("../img/soc-insta.svg"); }
    .socials__link-tripadvisor {
      position: relative;
      top: 8px;
      background-image: url("../img/soc-tripadvisor.svg");
      align-self: flex-end;
      flex-shrink: 0;
      margin: 0 auto; }

.header__btn {
  display: none;
  position: absolute;
  top: 37px;
  z-index: 10;
  width: 150px;
  font-size: 14px;
  text-transform: uppercase;
  border: none;
  background-color: transparent; }
  .header__btn::before {
    content: "";
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    display: block;
    margin: 0 auto;
    width: 1px;
    height: 50px; }
  .header__btn-asia {
    font-family: "Fontin Sans CR", sans-serif;
    color: rgba(236, 168, 106, 0.65); }
    .header__btn-asia::before {
      background-color: #eca86a; }
  .header__btn-italy {
    font-family: "Synerga Pro", serif;
    color: rgba(20, 21, 20, 0.65); }
    .header__btn-italy::before {
      background-color: black; }
  .header__btn-active {
    display: block; }

.footer {
  position: relative;
  width: 100%; }
  .footer .footer__soc li a {
    display: block; }
  .footer-italy {
    background-color: black;
    background-position: center -115px;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: "Synerga Pro", serif; }
    .footer-italy .footer__time-italy {
      display: inline-block; }
    .footer-italy .footer__soc-italy {
      display: flex; }
  .footer-asia {
    font-family: "Fontin Sans CR", sans-serif; }
    .footer-asia::after {
      content: url("../img/footer-dragon.svg");
      position: absolute;
      bottom: -25px;
      z-index: 0;
      right: 0;
      display: block;
      mix-blend-mode: overlay; }
    .footer-asia .footer__time-asia {
      display: inline-block; }
    .footer-asia .footer__soc-asia {
      display: flex; }
    .footer-asia .footer__middle,
    .footer-asia .footer__right {
      position: relative;
      z-index: 10; }
  .footer__wrapper {
    display: flex;
    justify-content: space-between;
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 24px;
    padding-bottom: 34px;
    font-family: inherit; }
  .footer__logo {
    display: block;
    width: 64px;
    height: 114px;
    margin-bottom: 14px; }
  .footer__copyright {
    width: 100%;
    font-size: 12px;
    font-family: inherit; }
  .footer__locationPin {
    display: block;
    width: 55px;
    height: auto;
    margin: 0 auto;
    background-color: transparent;
    border: none; }
  .footer__middle {
    display: flex;
    flex-direction: column;
    padding: 0 5%;
    font-family: inherit; }
  .footer__info {
    width: 210px;
    margin-top: auto;
    font-size: 14px;
    color: #b4b3a5;
    font-family: inherit; }
    .footer__info > span:nth-last-of-type(n + 2) {
      margin-bottom: 8px; }
  .footer__time, .footer__addr, .footer__tel {
    display: inline-block;
    width: 100%; }
  .footer__time {
    display: none; }
  .footer__tel a {
    color: #b4b3a5; }
  .footer__right {
    display: flex;
    flex-direction: column;
    width: 410px;
    font-family: inherit; }
  .footer__soc {
    display: none;
    justify-content: space-between;
    width: 67%;
    margin-left: auto;
    list-style: none; }
  .footer__links {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    font-size: 12px;
    color: #b4b3a5;
    font-family: inherit; }
  .footer__btn {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: inherit;
    font-family: inherit; }
  .footer__link {
    text-decoration: none;
    color: inherit;
    font-family: inherit; }

.mid {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }
  .mid svg {
    margin-bottom: -1vw; }

/* CONTENT UNDERNEATH*/
/* MIDDLE ROWS */
.middle {
  height: 65vw;
  display: none; }

.row {
  height: 50%;
  display: flex;
  position: relative; }

.row .hall {
  width: 48%;
  background-size: cover;
  background-position: center; }

.row .about {
  width: 40%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 7%;
  text-align: center;
  color: #dab077; }

.middle.italy .row .about {
  color: #000000; }

.row .about p {
  margin-bottom: auto; }

.row .about img {
  width: 8vw;
  margin-top: 15%; }

.row .about h3 {
  font-size: 1.5rem;
  margin: 1vw 0; }

.switcher {
  width: 12%;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer; }

.italy .switcher {
  justify-content: center; }

.switcher .fenixlogo {
  width: 5vw;
  position: relative; }

.switcher .smallframe {
  position: absolute;
  object-fit: contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 92%; }

.switcher .switch-logo,
.switcher .simetric {
  position: initial;
  opacity: 1;
  transform: unset; }

.switcher .simetric {
  width: 2vw;
  position: relative;
  bottom: -1vw;
  right: unset; }

.switcher img:not(.smallframe) {
  height: 5vw;
  object-fit: contain; }

.row .horizontal {
  position: absolute;
  bottom: 10%;
  right: 12%;
  width: 70%; }

.middle.italy .row .horizontal {
  right: unset;
  left: 12%; }

.row .menu-block {
  width: 33%;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }

.italy .row .menu-block {
  color: #141514; }

.row .plate {
  width: 55%;
  background-size: cover;
  background-position: center;
  position: relative; }

.row .menuframe {
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%); }

.row .menuframe:nth-child(1) {
  width: 55%; }

.row .menuframe:nth-child(2) {
  width: 53%; }

.plate h3 {
  font-size: 2.4rem; }

.plate .fadeline {
  width: 50%;
  margin: 0.8vw 0; }

.plate p {
  width: 65%; }

.plate .goat {
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translate(0, -50%);
  width: 3vw; }

/* CULTURE */
.culture {
  height: 60vw;
  background-position: center;
  background-size: cover;
  position: relative; }

.culture .stripe {
  position: absolute;
  width: 100%;
  object-fit: contain; }

.culture .stripe:nth-child(1) {
  bottom: 20%; }

.culture .stripe:nth-child(2) {
  bottom: 10%; }

.culture .row {
  display: flex;
  height: 50%;
  justify-content: space-around;
  padding: 0 10%;
  color: #f3ebe0; }

.culture .half {
  width: 50%; }

.culture .row:not(.second) .half.img img {
  margin-top: 15%; }

.culture h2 {
  font-size: 2rem;
  line-height: 85%;
  margin: 1vw 0 3vw;
  color: #dab077; }

.culture p {
  width: 85%; }

.culture h4 {
  font-size: 1.4rem; }

.culture .half.img img {
  width: 100%;}

.culture .second .half.img img {
  width: 100%;
  padding-right: 0;
  padding-left: 15%; }

/* SERVICES */
.services {
  height: 55vw;
  display: flex;
  position: relative; }

.services .blocks {
  display: flex;
  flex-direction: column;
  position: relative; }

.services .blocks .row {
  display: flex; }

.services .blocks .row .left {
  width: 33%;
  background-position: center;
  background-size: cover;
  padding: 2vw;
  position: relative;
  color: #dab077; }

.services.italy .blocks .row .left {
  color: #141514; }

.services h2 {
  line-height: 100%;
  margin-bottom: 1.5vw; }

.services .blocks .row .left img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30%; }

.services .blocks .row .colorful {
  width: 25%;
  background-color: #1a7f8e;
  padding: 2vw; }

.services .blocks .row .place {
  flex-basis: 100%;
  background-position: center;
  background-size: cover;
  padding: 2vw;
  position: relative; }

.services .blocks .row .place p.middler {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: rgba(236, 168, 106, 0.65);
  font-size: 1.4rem; }

.services .blocks .row .place p:not(.middler) {
  width: 56%; }

.services.italy .blocks .row .colorful {
  width: 25%;
  background-color: #a41838; }

.services .blocks .row .right {
  width: 67%;
  background-position: center;
  background-size: cover; }

.services .blocks .row:nth-child(1) {
  height: 60%; }

.services .blocks .row:nth-child(2) {
  height: 40%; }

/* FOOTER */
footer {
  background-position: center;
  background-size: cover; }

.container {
  width: 85%;
  margin: 0 auto; }

footer .imgrow {
  display: flex;
  align-items: center;
  justify-content: space-between; }

footer .pin,
footer .socials img {
  width: 3vw; }

footer .socials img {
  margin: 0 1vw;
  object-fit: contain; }

footer .logo {
  width: 18vw;
  height: 7vw;
  object-fit: contain;
  margin-top: 1vw; }

footer .container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; }

footer .textrow {
  color: #b4b3a5;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2vw;
  line-height: 135%; }

footer .copyright {
  width: 28vw; }

footer .backontop {
  text-decoration: underline;
  cursor: pointer; }

/*MOBILE VERSION*/
@media screen and (max-width: 768px) {
  #popup .line {
    display: none; }
  .mobile {
    display: initial; }
  .desktop {
    display: none; }
  #doors {
    flex-direction: column; }
  #doors .half {
    width: 100%;
    height: 100%; }
  #doors .half:nth-of-type(1) {
    background-position: 65% 65%;
    background-size: 180%; }
  .gotoaction {
    text-align: center;
    margin: 0;
    width: 100%;
    margin-bottom: 15%; }
  .burger.mobile {
    width: 12vw;
    opacity: 1;
    top: 5vw;
    right: 5vw;
    left: unset;
    position: fixed; }
  .gotoaction .orange {
    display: none; }
  .gotoaction h1 {
    font-size: 7rem;
    margin-top: 6vw; }
  .gotoaction p {
    font-size: 3.5rem;
    margin-right: 15%;
    line-height: 135%;
    text-align: left;
    margin-left: 17%;
    color: #dab077; }
  #doors .gotoaction p {
    margin-left: 22%;
    margin-top: 30%; }
  .darker {
    height: 100%;
    top: unset; }
  .frame {
    display: none;
    pointer-events: none; }
  #doors .half.shrinked {
    display: none; }
  #doors .half.opened {
    width: 100%; }
  #doors .half:nth-of-type(1) {
    background-position: center top;
    background-size: cover; }
  .burger:not(.mobile) {
    display: none; }
  .doorlogo {
    position: absolute;
    width: 20vw;
    top: 5vw;
    opacity: 0;
    left: 5vw; }
  .half .socials {
    display: none; }
  .info-block {
    width: 90%;
    margin: 0 auto; }
  .horizont {
    width: 195%; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-variant: unset; }
  .info-block h2 {
    font-size: 7rem;
    margin-bottom: 2vw; }
  .info-block p {
    font-size: 4rem; }
  .vertical {
    display: none; }
  .discover p {
    font-size: 4rem; }
  .discover img {
    height: 20vw;
    margin-top: 2vw; }
  .address {
    display: none; }
  .switcher {
    display: none; }
  .row {
    height: unset;
    flex-direction: column; }
  .middle .row.second {
    flex-direction: column-reverse; }
  .middle {
    height: unset; }
  .row .hall {
    width: 100%;
    height: 80vw; }
  .row .about {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 28px 38px 31px; }
  .row .about img,
  .row .horizontal {
    display: none; }
  .row .about p {
    margin-bottom: unset; }
  .row .about h3 {
    font-size: 7rem; }
  .row .about p {
    margin-bottom: unset;
    font-size: 4rem; }
  .row .plate {
    width: 100%;
    height: 125vw;
    padding: 5%; }
  .plate .fadeline {
    width: 100%;
    margin: 2vw 0; }
  .plate h3 {
    font-size: 7rem; }
  .plate p {
    width: 90%;
    font-size: 4rem; }
  .row .menu-block {
    width: 100%;
    height: 95vw;
    margin-top: -20%; }
  .row .menuframe {
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translate(-50%, -50%);
    z-index: 99; }
  .menu-block > * {
    top: -40%;
    position: relative;
    z-index: 9; }
  .culture {
    height: unset; }
  .culture .half.img img {
    margin: unset; }
  .culture .half {
    width: 100%; 
 margin-bottom: 5px;}
  .culture .stripe {
    position: absolute;
    width: 100%;
    object-fit: cover;
    height: 25vw;
    object-position: right; }
  .culture h4,
  .culture p {
    font-size: 3.5rem; }
  .culture p {
    width: 95%; }
  .culture h4 {
    width: 80%;
    margin: 2vw 0; }
  .culture h2 {
    font-size: 6rem;
    line-height: 100%;
    margin: 3vw 0;
    color: #dab077; }
  .culture .row {
    padding: 0 5%; }
  .culture .row.second {
    flex-direction: column-reverse;
    margin-top: 15vw;
    padding-bottom: 15vw; }
  .culture .row:not(.second) .half:not(.img) {
    padding-top: 2vw; }
  .culture .stripe:nth-child(2) {
    bottom: 16%; }
  .services {
    height: unset; }
  .services .blocks .row:nth-child(1) {
    flex-direction: column-reverse; }
  .services .blocks .row .right {
    width: 100%;
    padding: 5vw;
    height: 220vw; }
  .services .blocks .row:nth-child(2) {
    flex-direction: row; }
  .services .blocks .row .place p:not(.middler) {
    display: none; }
  .services .blocks .row .place {
    padding: 5vw; }
  .services .blocks {
    width: 100%; }
  .services h2 {
    font-size: 5rem; }
  .services .blocks .row .place {
    padding: 2vw;
    max-width: 33%; }
  .services .blocks .row .place:nth-child(2) {
    display: flex;
    align-items: center; }
  .services .blocks .row .place:nth-child(3) h2 {
    position: absolute;
    bottom: 2vw;
    left: -30%;
    white-space: nowrap; } }

.btn {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  border: 1px solid transparent;
  background-color: transparent;
  cursor: pointer;
  outline: none; }
  .btn-md {
    padding-left: 35px;
    padding-right: 35px; }
  .btn-sm {
    padding-left: 12px;
    padding-right: 12px; }
  .btn-light {
    border-color: #f0f1f3;
    color: #f0f1f3; }
    .btn-light:active {
      background-color: #d4d4d4;
      color: #080808; }
    .btn-light:hover, .btn-light:focus {
      background-color: #f0f1f3;
      color: #141514; }
  .btn-dark {
    border-color: #eca86a;
    color: #eca86a; }
    .btn-dark:active {
      background-color: #ecc187;
      color: #0e0e0e; }
    .btn-dark:hover, .btn-dark:focus {
      background-color: #dab077;
      color: #333; }

.content {
  position: absolute;
  top: 27%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-right: auto;
  background-position: center;
  background-size: inherit;
  background-repeat: no-repeat;
  background-color: #fff;
  opacity: 0;
  transition: opacity 0.3s ease-in-out; }
  .content__soc {
    display: flex;
    width: 100px;
    margin-top: 23px;
    margin-bottom: 86px;
    justify-content: space-between;
    align-items: center;
    list-style: none; }
    .content__soc-asia .content__link a {
      color: #eca86a; }
    .content__soc-italy .content__link a {
      color: black; }
  .content__link a {
    font-size: 0;
    text-decoration: none; }
  .content__wrapper {
    display: flex;
    width: 100%;
    padding-left: 146px;
    padding-top: 34px;
    padding-bottom: 31px;
    background-color: rgba(20, 21, 20, 0.35); }
  .content__btn {
    position: absolute;
    top: 57%;
    transform: translateY(-50%);
    margin-top: 140px;
    margin-bottom: 50px; }
    .content__btn-asia {
      color: rgba(236, 168, 106, 0.65); }
      .content__btn-asia::after {
        background-color: #eca86a; }
      .content__btn-asia:focus, .content__btn-asia:hover {
        color: rgba(236, 168, 106, 0.8); }
      .content__btn-asia:active {
        color: #eca86a; }
    .content__btn-italy {
      color: rgba(20, 21, 20, 0.65); }
      .content__btn-italy::after {
        background-color: #141514; }
      .content__btn-italy:focus, .content__btn-italy:hover {
        color: rgba(20, 21, 20, 0.8); }
      .content__btn-italy:active {
        color: #141514; }
    .content__btn::after {
      content: "";
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      height: 60px; }

@media screen and (max-width: 768px) {
  .content__btn {
    top: 40%; } }

.info {
  width: 560px; }
  .info__title {
    font-size: 32px;
    line-height: 1.35;
    color: #f3f4f6; }
  .info__text {
    font-size: 16px;
    line-height: 1.25;
    color: #f9f6f2; }
  .info__btn {
    margin: 30px 0; }

.half__btn {
  padding: 10px 25px;
  margin-top: 10px; }

@media screen and (max-width: 768px) {
  .second.opened .burger,
  .second.opened .pin,
  .first.opened .pin,
  .first.opened .burger {
    display: none; }
  .popup {
    flex-direction: column; }
    .popup__half {
      width: 100%; }
    .popup::after, .popup::before {
      width: 130px;
      height: 1px;
      top: 40%;
      left: unset;
      margin: 0; }
    .popup::after {
      right: 10%; }
    .popup::before {
      left: 10%; }
    .popup__icon {
      display: block; }
    .popup__pdfs {
      margin-top: 89px; }
    .popup__footer {
      width: 100%; }
    .popup__copyright {
      width: 281px;
      margin-left: auto;
      margin-right: auto; }
  .pdfs__link {
    font-size: 16px;
    border: none;
    background-color: transparent; }
  .pdfs__container {
    width: 313px;
    margin: 35px; }
  .pdfs__icon {
    display: none; }
  .socials {
    width: 300px; }
  .mapper {
    margin-top: 91px;
    padding: 0; }
    .mapper__addr {
      width: 277px;
      padding-left: 55px;
      margin: 0 auto;
      font-size: 16px;
      text-transform: initial; }
    .mapper__map {
      display: none; }
    .mapper__container {
      display: none; }
    .mapper__bot {
      margin-top: 25px;
      text-transform: lowercase; }
      .mapper__bot::after {
        display: none; }
  .footer-italy {
    background-color: white;
    background-position: 0px center;
    background-blend-mode: hard-light; }
  .footer-asia::after {
    display: none; }
  .footer__wrapper {
    flex-direction: column;
    padding: 37px 0 33px;
    align-items: center; }
  .footer__left {
    order: 1; }
  .footer__logo {
    display: none; }
  .footer__copyright {
    text-align: center; }
  .footer__middle {
    position: relative;
    padding: 0; }
  .footer__locationPin {
    position: absolute;
    top: -15px;
    left: 0;
    width: 45px;
    margin: 0; }
  .footer__info {
    display: flex;
    flex-direction: column;
    width: auto;
    text-align: center;
    font-size: 16px; }
  .footer__addr {
    order: -1;
    margin-bottom: 39px;
    padding-left: 55px; }
  .footer__time {
    color: #c4a394;
    margin-bottom: 26px; }
  .footer__tel {
    color: #b4b3a5;
    margin-bottom: 32px; }
    .footer__tel a {
      color: #b4b3a5; }
  .footer__right {
    flex-direction: column-reverse;
    align-items: center; }
  .footer__soc {
    margin: 0;
    margin-bottom: 30px; }
  .footer__links {
    margin-bottom: 34px; }
  .footer__link {
    display: inline-block;
    margin: 0 auto;
    font-size: 16px;
    color: #c4a394;
    text-decoration: underline; }
  .footer__btn {
    display: none; }
  #doors .half {
    position: relative; }
  .half__btn {
    position: absolute;
    top: 23%;
    left: 46%;
    transform: translate(-50%);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    color: #f9f6f2;
    border: none; }
    .half__btn.btn-light {
      background-color: rgba(249, 246, 242, 0.18); }
    .half__btn.btn-dark {
      background-color: rgba(236, 168, 106, 0.18); }
  .content__soc {
    display: none; }
  .content__wrapper {
    margin-top: 36px;
    padding: 0;
    padding-left: 23px;
    padding-right: 19px; }
  .content__info {
    margin-top: 23px; }
  .content__btn {
    margin-top: 170px; }
  .info__title {
    width: 302px;
    font-size: 24px;
    line-height: 1.05; }
  .info__text {
    margin-top: 14px;
    font-size: 14px; }
  .info__btn {
    margin-top: 55px; }
  #middle .row.first {
    position: relative;
    height: 541px; }
  #middle .about,
  #middle .hall {
    position: absolute; }
  #middle .hall {
    top: 0; }
  #middle .about {
    top: 300px; }
  #middle .menu-block {
    background-image: unset !important; }
  #middle .row.second {
    position: relative;
    flex-direction: unset;
    height: 697px;
    background-image: url("../img/lightbg.jpg"); }
  #middle .plate,
  #middle .menu-block {
    position: absolute; }
  #middle .plate {
    top: 0; }
  #middle .row .menuframe {
    z-index: 0; }
  #middle .menu-block {
    top: 540px;
    color: #f3f3f6; }
    #middle .menu-block > img {
      background-color: rgba(20, 21, 20, 0.35); }
  #services .menu-block {
    color: #f3f3f6; }
    #services .menu-block > img {
      background-color: rgba(20, 21, 20, 0.35);
      z-index: 1; }
  #servicesAsia {
    background-image: url("../img/bg-gradient-asia.jpg"); }
  #middleAsia {
    background-image: url("../img/bg-gradient-asia.jpg"); } }

.noScroll {
  height: 100vh;
  overflow-y: hidden; }

.nav {
  position: fixed;
  z-index: 10;
  top: 0px;
  left: 0;
  right: 0; }
  .nav__wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 91%;
    max-width: 1313px;
    padding-top: 57px;
    margin: 0 auto;
    transition: width 0.3s ease; }
  .nav__menu {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .nav__languageList {
    list-style: none; }
    .nav__languageList li {
      text-align: center;
      padding: 0 5px; }
      .nav__languageList li:nth-last-child(n + 2) {
        margin-bottom: 5px; }
  .nav__language {
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    font-family: "Fontin Sans CR", sans-serif;
    color: #c4a394; }
    .nav__language:hover, .nav__language:focus, .nav__language:active {
      text-decoration: underline; }
  .nav__icon {
    position: relative;
    top: 0;
    width: 45px;
    display: block;
    height: auto;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: top 0.3s ease; }
    .nav__icon:hover, .nav__icon:focus {
      opacity: 0.9; }
    .nav__icon:active {
      top: 1px; }
    .nav__icon-menu {
      color: #c4a394; }
    .nav__icon-location {
      color: #f3f4f6; }
  .nav__logo {
    position: absolute;
    top: 50vh;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    color: #f9f6f2;
    text-align: center;
    pointer-events: none; }
    .nav__logo svg {
      width: 71px;
      height: 64px; }
    .nav__logo p {
      display: none;
      width: 220px;
      font-size: 12px; }
    .nav__logo span {
      display: inline-block; }
  .nav.seesaw .nav__wrapper {
    width: 72%;
    max-width: 1080px; }
  .nav.seesaw .nav__logo p {
    display: block; }
  .nav.seesaw-asia .nav__wrapper {
    margin-left: 4.4%;
    margin-right: auto; }
  .nav.seesaw-asia .nav__icon {
    color: #c4a394; }
  .nav.seesaw-asia .nav__logo p {
    font-family: "Fontin Sans CR", sans-serif; }
  .nav.seesaw-asia .nav__language {
    font-family: "Fontin Sans CR", sans-serif;
    color: #c4a394; }
  .nav.seesaw-italy .nav__wrapper {
    margin-right: 4.4%;
    margin-left: auto; }
  .nav.seesaw-italy .nav__icon {
    color: black; }
  .nav.seesaw-italy .nav__logo {
    color: black; }
  .nav.seesaw-italy .nav__logo p {
    font-family: "Synerga Pro", serif; }
  .nav.seesaw-italy .nav__language {
    font-family: "Synerga Pro", serif;
    color: black; }
  .nav.seesaw .nav__logo {
    top: 98px;
    width: 100%; }
    .nav.seesaw .nav__logo p {
      margin-top: 62px;
      margin-left: auto;
      text-align: right; }
  .nav.nav-popup .nav__wrapper {
    width: 91%;
    max-width: 1313px; }
  .nav.nav-popup.onScroll {
    background-color: transparent; }
    .nav.nav-popup.onScroll__wrapper {
      width: 91%;
      max-width: 1313px;
      padding-top: 57px;
      margin: 0 auto; }
  .nav.nav-popup .nav__icon {
    color: #c4a394; }
  .nav.nav-popup .nav__logo {
    opacity: 0.2; }
  .nav.onScroll {
    background-color: rgba(0, 0, 0, 0.3); }
    .nav.onScroll .nav__wrapper {
      display: flex;
      width: 90%;
      max-width: 1300px;
      padding-top: 12px;
      padding-bottom: 12px; }
    .nav.onScroll .nav__logo {
      position: static;
      top: unset;
      left: unset;
      transform: unset;
      display: flex; }
      .nav.onScroll .nav__logo svg {
        display: none; }
      .nav.onScroll .nav__logo p {
        margin: 0 0 0 auto;
        color: #fff; }
    .nav.onScroll .nav__icon {
      color: #fff; }
    .nav.onScroll .nav__icon-location {
      display: none; }

@media screen and (max-width: 768px) {
  .nav {
    width: 88.26%;
    min-width: 331px;
    padding-top: 15px;
    margin: 0 auto; }
    .nav__wrapper {
      margin: 0;
      padding: 0;
      width: 100%; }
    .nav__menu {
      flex-direction: row;
      align-items: center;
      margin-left: auto; }
    .nav .nav__icon-location {
      display: none; }
    .nav .nav__icon-menu {
      margin-left: auto; }
    .nav.seesaw .nav__wrapper {
      width: 100%;
      flex-direction: row-reverse;
      align-items: self-start; }
    .nav.seesaw .nav__logo {
      position: static;
      margin: 0; }
    .nav.seesaw .nav__logo p {
      display: none; }
    .nav.seesaw-asia .nav__wrapper,
    .nav.seesaw-italy .nav__wrapper {
      margin: 0 auto; }
    .nav.seesaw-asia .nav__icon,
    .nav.seesaw-asia .nav__logo {
      color: #c4a394; }
    .nav.seesaw-italy .nav__icon,
    .nav.seesaw-italy .nav__language {
      color: #c4a394; }
    .nav.seesaw-italy.nav-popup .nav__icon {
      color: #c4a394; }
    .nav.seesaw-italy .nav__logo {
      color: black; }
    .nav.seesaw .nav__logo {
      top: 0;
      transform: unset;
      left: 0;
      width: auto; }
    .nav.nav-popup .nav__wrapper {
      width: 100%; }
    .nav.nav-popup .nav__icon {
      color: #c4a394; }
    .nav.onScroll {
      padding: 0;
      margin: 0;
      width: 100%; }
      .nav.onScroll .nav__wrapper {
        width: 88.26%;
        min-width: 331px;
        margin: 0 auto; } }

.menuFrame {
  width: 60%;
  border: 1px solid;
  padding: 3px 4.5px; }
  .menuFrame__title {
    font-size: 18px;
    margin-bottom: 16px; }
  .menuFrame__text {
    font-size: 16px;
    margin-bottom: 48px; }
  .menuFrame__icon {
    margin-bottom: 54px; }
  .menuFrame__btn {
    padding: 12px; }
  .menuFrame__subTitle {
    font-size: 10px;
    margin-top: 5px; }
  .menuFrame-italy {
    border-color: #141514;
    font-family: "Synerga Pro", serif; }
    .menuFrame-italy .menuFrame__title,
    .menuFrame-italy .menuFrame__text,
    .menuFrame-italy .menuFrame__btn {
      font-family: inherit;
      color: #141514; }
    .menuFrame-italy .menuFrame__icon svg {
      color: #141514;
      fill: #141514; }
    .menuFrame-italy .menuFrame__btn {
      border-color: #141514; }
    .menuFrame-italy .menuFrame__subTitle {
      color: rgba(249, 246, 242, 0.65); }
  .menuFrame-asia {
    border-color: #dab077;
    font-family: "Fontin Sans CR", sans-serif; }
    .menuFrame-asia .menuFrame__title,
    .menuFrame-asia .menuFrame__text,
    .menuFrame-asia .menuFrame__btn {
      font-family: inherit;
      color: #dab077; }
    .menuFrame-asia .menuFrame__icon svg {
      color: #dab077;
      fill: #dab077; }
    .menuFrame-asia .menuFrame__btn {
      border-color: #ffffff;
      color: #fff; }
    .menuFrame-asia .menuFrame__subTitle {
      color: #f9f6f2; }
  .menuFrame__sub {
    border: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 39px 23px 23px;
    color: #dab077;
    text-align: center; }

@media screen and (max-width: 768px) {
  .menuFrame {
    width: 65%;
    background-color: rgba(20, 21, 20, 0.45); }
    .menuFrame__sub {
      padding: 37px 25px 29px; }
    .menuFrame__title {
      font-size: 16px; }
    .menuFrame-italy .menuFrame__title,
    .menuFrame-italy .menuFrame__text {
      color: #ffffff; }
    .menuFrame-italy .menuFrame__icon svg {
      color: #fbfbfb;
      fill: #fbfbfb; }
    .menuFrame-italy .menuFrame__btn {
      color: #fff;
      border-color: #fff; } }

.slider__container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  margin: auto;
  background-color: #fff;
  overflow: hidden; }
  .slider__container::before, .slider__container::after {
    content: "";
    position: absolute;
    bottom: 12.3%;
    transform: translateY(-50%);
    z-index: 10;
    display: block;
    height: 1px;
    width: 45%;
    background-color: #eca86a; }
  .slider__container::before {
    left: 0; }
  .slider__container::after {
    right: 0; }
  .slider__container-asia {
    font-family: "Fontin Sans CR", sans-serif; }
    .slider__container-asia .slider__nav {
      font-family: inherit;
      color: #eca86a; }
  .slider__container-italy .slider__title,
  .slider__container-italy .slider__subTitle,
  .slider__container-italy .slider__numbertext {
    font-family: "Synerga Pro", serif; }
  .slider__container-italy .slider__numbertext,
  .slider__container-italy .slider__arrow {
    color: #fafeff; }
  .slider__container-italy::before, .slider__container-italy::after {
    background-color: #fafeff; }

.slider__content {
  position: absolute;
  top: 33px;
  left: 41px;
  z-index: 10;
  width: 200px;
  font-family: inherit; }

.slider__title {
  padding-bottom: 26px;
  border-bottom: 1px solid #eca86a;
  font-family: inherit;
  font-size: 36px;
  line-height: 1;
  color: #f9f6f2; }

.slider__hint {
  display: none;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px; }

.slider__subTitle {
  font-family: inherit;
  font-size: 14px;
  color: #eca86a; }

.slider__slide {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  width: 100%; }

.slider__img {
  display: block;
  width: 100%;
  height: auto; }

.slider__nav {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 9.7%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120px;
  margin: 0 auto; }

.slider__arrow {
  cursor: pointer;
  font-weight: bold;
  transition: 0.6s ease;
  user-select: none; }
  .slider__arrow-prev:hover svg,
  .slider__arrow-next:hover svg,
  .slider__arrow-prev:focus svg,
  .slider__arrow-next:focus svg {
    opacity: 0.8; }
  .slider__arrow-prev:hover svg,
  .slider__arrow-next:hover svg,
  .slider__arrow-prev:focus svg,
  .slider__arrow-next:focus svg {
    opacity: 0.9; }
  .slider__arrow-next svg {
    transform-origin: center;
    transform: rotate(180deg); }

.slider__numbertext {
  color: #e8b688;
  font-size: 16px; }

@media screen and (max-width: 768px) {
  .slider__container::after, .slider__container::before {
    bottom: 14.2%;
    width: 30%; }
  .slider__content {
    top: 33px;
    left: 23px; }
  .slider__hint {
    display: flex; }
  .slider__title {
    font-size: 24px;
    padding-bottom: 16px; }
  .slider__nav {
    width: 100px;
    bottom: 8%; }
  .slider__arrow svg {
    width: 14px;
    height: auto; }
  .slider__numbertext {
    font-size: 14px; } }

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s; }

@-webkit-keyframes fade {
  from {
    opacity: 0.4; }
  to {
    opacity: 1; } }

@keyframes fade {
  from {
    opacity: 0.4; }
  to {
    opacity: 1; } }
